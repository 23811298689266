import React, { useEffect, useState } from 'react';

import styled, { createGlobalStyle } from 'styled-components';
import { useTranslation } from 'react-i18next';

import Img1 from '../../assets/images/error-page-img1.png';
import Img2 from '../../assets/images/error-page-img2.png';
import Img3 from '../../assets/images/error-page-img3.png';

const Global = createGlobalStyle`body{margin:0;}`;

const Container = styled.div`
  min-height: 100vh;
  background-color: #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;

const Wrapper = styled.div`
  max-width: 800px;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 #0000001f;
  border-radius: 16px;
  display: flex;
  justify-content: center;

  > div {
    max-width: 648px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const Header = styled.h1`
  margin-top: 0;
  margin-bottom: 52px;
  font-family: Poppins, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0;
  text-align: center;
  color: #87683b;
`;

const Section = styled.section`
  max-width: 500px;
  margin: 0 auto;

  > div {
    margin-bottom: 28px;
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #87683b;
`;

const InfoText = styled.div`
  position: relative;
  display: flex;

  img {
    height: 20px;
    margin-right: 20px;
  }

  p {
    margin: 0;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    color: #636c79;
  }
`;

const CounterText = styled.p`
  margin: 28px auto;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: center;
  color: #636c79;
`;

const Button = styled.button`
  cursor: pointer;
  max-width: 288px;
  width: 100%;
  padding: 6px;
  margin: 0 auto;
  border: none;
  background-color: #87683b;
  border-radius: 8px;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0;
  text-align: center;
  color: #fff;

  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
  }
`;

const ConnectionProblemComponent = () => {
  const { t } = useTranslation();
  const [time, setTime] = useState(30);
  useEffect(() => {
    if (time > 0) {
      const interval = setInterval(() => {
        setTime(time - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      window.location.reload();
    }
  }, [time]);
  return (
    <>
      <Global />
      <Container>
        <Wrapper>
          <div>
            <Header>
              {t(
                '$*connectionProblemComponent.heading1',
                'Ups, wystąpił problem z załadowaniem strony.'
              )}
            </Header>
            <Section>
              <InfoText>
                <img src={Img1} alt="" />
                <p>
                  {t(
                    '$*connectionProblemComponent.heading2',
                    'Sprawdź, czy Twoje połączenie z internetem jest stabilne.'
                  )}
                </p>
              </InfoText>
              <InfoText>
                <img src={Img2} alt="" />
                <p>
                  {t(
                    '$*connectionProblemComponent.heading3',
                    'Jeśli tak, spróbuj otworzyć stronę w trybie incognito.'
                  )}
                </p>
              </InfoText>
              <InfoText>
                <img src={Img3} alt="" />
                <p>
                  {t(
                    '$*connectionProblemComponent.heading4',
                    'Jeżeli nadal pojawia się problem, prawdopodobnie nasz serwer ma chwilową przerwę.'
                  )}
                </p>
              </InfoText>
            </Section>
            <Divider />
            <CounterText>
              {t('$*connectionProblemComponent.counter', {
                defaultValue: `Strona zostanie odświeżona automatycznie za {{sec}}s`,
                sec: time,
              })}
            </CounterText>
            <Button onClick={() => window.location.reload()}>
              Odśwież teraz
            </Button>
          </div>
        </Wrapper>
      </Container>
    </>
  );
};

export default ConnectionProblemComponent;
